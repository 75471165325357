$gray-800: #333333 !default;
$gray-900: #2F2F2F !default;
$input-color: #0F0F0F;

$red:     #d84251 !default;

// scss-docs-start border-radius-variables
$border-radius:               .225rem !default;
$border-radius-sm:            .15rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-xl:            .8rem !default;
$border-radius-2xl:           .16rem !default;
$border-radius-pill:          40rem !default;
// scss-docs-end border-radius-variables