#login_card {
    #logo {
        width: 24px;
        margin-right: 5px;
    }

    .card-body {
        padding: 40px;
    }
    
    .card-footer {
        padding: 20px 40px;
    }

    a {
        float: right;
    }
}