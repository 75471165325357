
@font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto-Light.ttf);
    font-weight: normal;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto-Medium.ttf);
    font-weight: bold;
    font-style: normal;
  }
  
  
  body {
    font-family: 'Roboto';
    font-weight: normal;
  }
  
  body h1 {
    font-family: 'Roboto';
    font-weight: 600;
  }